import React from 'react';

import './signup.css';
import bannerVideo from '../videos/springanimation-notext.mp4';

const LEARNING_FORM_ID = '1388940';
const SUBSCRIBE_FORM_ID = '1407219';
const DEFAULT_FORM_ID = '1407928';

class Signup extends React.Component {
  render() {
    let form,
      { cta } = this.props;
    switch (cta) {
      case 'learningintopractice':
        form = {
          id: LEARNING_FORM_ID,
          title: 'Put your learning into practice today.',
          subTitle:
            'Knowledge is only half the battle; Execution is everything. Get 4 of our best templates to maximize your productivity for FREE and start living an intentional life.',
          buttonText: 'Get My Free Templates',
        };
        break;
        case 'subscribe':
          form = {
            id: SUBSCRIBE_FORM_ID,
            title: 'Subscribe to our weekly newsletter.',
            subTitle: "Every Sunday, I share a ✉ with community updates, honest sharing, content I've learned from, and a meaningful prompt to get your week going.",
            buttonText: 'Subscribe 😄',
          };
          break;
      default:
        form = {
          id: DEFAULT_FORM_ID,
          title: 'Join the Co-x3 Community!',
          subTitle: "If you are as passionate as we are about innovation, subscribe to see what we've been working on and claim first dibs to new collaboration opportunities. 👍🏻",
          buttonText: 'Subscribe 😄',
        };
    }
    return (
      <form
        action={`https://app.convertkit.com/forms/${form.id}/subscriptions`}
        className="seva-form formkit-form"
        method="post"
        min-width="400 500 600 700 800"
        style={{
          boxShadow: 'rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px',
          backgroundColor: 'white',
          borderRadius: '6px',
          display: 'inline-block',
        }}
      >
        <div data-style="full">
          <div
            data-element="column"
            className="formkit-column"
            style={{ backgroundColor: '#ebf6ff', 
            overflow: 'hidden',
            position: 'relative'}}
          >
          <video autoPlay muted loop playsinline>
            <source  src={bannerVideo} type="video/mp4" />
          </video>
            <h1
              className="formkit-header"
              data-element="header"
              style={{
                color: 'white',
                fontSize: '20px',
                fontWeight: 700,
                textAlign: 'left',
                position: 'relative',
              }}
            >
              {form.title}
            </h1>
            <div
              data-element="subheader"
              className="formkit-subheader"
              style={{ 
                textAlign: 'left', color: 'white',
                position: 'relative', }}
            >
              <p>{form.subTitle}</p>
            </div>
          </div>
          <div data-element="column" className="formkit-column">
            <ul
              className="formkit-alert formkit-alert-error"
              data-element="errors"
              data-group="alert"
            />

            <div data-element="fields" className="seva-fields formkit-fields">
              <div className="formkit-field">
                <input
                  className="formkit-input"
                  aria-label="Your first name"
                  name="fields[first_name]"
                  placeholder="Your first name"
                  type="text"
                  style={{
                    borderColor: 'rgb(227, 227, 227)',
                    borderRadius: '4px',
                    color: 'rgb(0, 0, 0)',
                    fontWeight: 400,
                  }}
                  required
                />
              </div>
              <div className="formkit-field">
                <input
                  className="formkit-input"
                  name="email_address"
                  aria-label="Your email address"
                  placeholder="Your email address"
                  required
                  type="email"
                  style={{
                    borderColor: 'rgb(227, 227, 227)',
                    borderRadius: '4px',
                    color: 'rgb(0, 0, 0)',
                    fontWeight: 400,
                  }}
                />
              </div>
              <button
                data-element="submit"
                className="formkit-submit formkit-submit"
                style={{
                  backgroundColor: 'black',
                  borderRadius: '24px',
                  color: 'white',
                  fontWeight: 700,
                  margin: '10px 0px 15px 0px',
                }}
              >
                <div className="formkit-spinner" />
                <span>{form.buttonText}</span>
              </button>
            </div>
            <div
              data-element="guarantee"
              className="formkit-guarantee"
              style={{
                color: 'var(--textNormal)',
                fontSize: '13px',
                fontWeight: 400,
              }}
            >
              <p>We won't send you spam. <em>Ever.</em></p>
              <p>
              Opt-out at any time.
              </p>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Signup;